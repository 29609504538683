var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-user","size":"lg","centered":"","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.isShowModal),callback:function ($$v) {_vm.isShowModal=$$v},expression:"isShowModal"}},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"h1 text-center"},[_vm._v(" Добавить нового "),(_vm.isAdmin)?_c('span',[_vm._v("администратора")]):_c('span',[_vm._v("пользователя")])]),_c('ValidationObserver',{ref:"AddUser",staticClass:"form-wrap",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('ValidationProvider',{attrs:{"rules":"required|min:2|max:30","name":"Фамилия","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Фамилия"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:2|max:30","name":"Имя","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"Имя"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email","name":"E-mail","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('div',{staticClass:"form-group"},[_c('b-form-input',{attrs:{"state":errors[0] ? false : (valid ? true : null),"placeholder":"E-mail"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"form-group"},[(!_vm.isAdmin)?_c('v-select',{attrs:{"options":_vm.roles,"searchable":false,"label":"title","clearable":false,"placeholder":"Выберите роль пользователя"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('v-select',{attrs:{"options":_vm.languages,"searchable":false,"label":"title","clearable":false,"placeholder":"Выберите язык для пользователя"},model:{value:(_vm.user.language),callback:function ($$v) {_vm.$set(_vm.user, "language", $$v)},expression:"user.language"}})],1),_c('div',{staticClass:"text-center mb-4"},[_c('b-button',{attrs:{"type":"submit","variant":"primary submit pl-5 pr-5"}},[_vm._v("добавить")])],1)],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }