<template>
    <b-modal id="add-user" size="lg" centered hide-footer no-close-on-backdrop v-model="isShowModal">
        <div class="modal-wrapper">
            <div class="h1 text-center">
                Добавить нового
                <span v-if="isAdmin">администратора</span>
                <span v-else>пользователя</span>
            </div>
            <ValidationObserver ref="AddUser" tag="div" class="form-wrap">
                <b-form slot-scope="{ validate, errors }" @submit.prevent="handleSubmit">
                    <ValidationProvider rules="required|min:2|max:30" name="Фамилия" tag="div">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <b-form-input
                                v-model="user.lastName"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder="Фамилия"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required|min:2|max:30" name="Имя" tag="div">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <b-form-input
                                v-model="user.firstName"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder="Имя"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required|email" name="E-mail" tag="div">
                        <div class="form-group" slot-scope="{ valid, errors }">
                            <b-form-input
                                v-model="user.email"
                                :state="errors[0] ? false : (valid ? true : null)"
                                placeholder="E-mail"
                            ></b-form-input>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </div>
                    </ValidationProvider>

                    <div class="form-group">
                        <v-select
                            v-if="!isAdmin"
                            :options="roles"
                            v-model="role"
                            :searchable="false"
                            label="title"
                            :clearable="false"
                            placeholder="Выберите роль пользователя"
                        >
                        </v-select>
                    </div>

                    <div class="form-group">
                        <v-select
                            :options="languages"
                            v-model="user.language"
                            :searchable="false"
                            label="title"
                            :clearable="false"
                            placeholder="Выберите язык для пользователя"
                        >
                        </v-select>
                    </div>

                    <div class="text-center mb-4">
                        <b-button type="submit" variant="primary submit pl-5 pr-5">добавить</b-button>
                    </div>
                </b-form>
            </ValidationObserver>
        </div>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: "AddUser",
        props: {
            isAdmin: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            ...mapActions([
                'getForumsList',
            ]),
            async handleSubmit() {
                let isValid = await this.$refs.AddUser.validate();
                if (isValid) {
                    const user = this.user;
                    if(this.role){
                        user.role = this.role.role;
                    }
                    this.$emit('addUser', user);
                }
            },
        },
        watch: {
            isShowModal(value){
                if(!value){
                    for(let item of Object.keys(this.user)){
                        this.user[item] = null;
                    }
                    this.role = null;
                    this.$nextTick(() => {
                        this.$refs.AddUser.reset();
                    });
                }
            }
        },
        data(){
            return{
                isShowModal: false,
                user: {
                    lastName: null,
                    firstName: null,
                    email: null,
                    language: {
                        title: 'Украинский',
                        lang: 'ua'
                    }
                },
                role: null,
                roles: [
                    {
                        title: 'Юзер',
                        role: 'BASE'
                    }, {
                        title: 'Премиум',
                        role: 'PREMIUM'
                    }, {
                        title: 'Администратор',
                        role: 'ADMIN'
                    }
                ],
                languages: [
                    {
                        title: 'Украинский',
                        lang: 'ua'
                    }, {
                        title: 'Английский',
                        lang: 'en'
                    }
                ],
            }
        }
    }
</script>

<style lang="scss" scoped>
    .form-wrap{
        max-width: 500px;
        margin: 0 auto;
    }
</style>